import { ENVIRONMENT } from '../app/_utils/env.util';
import { LOG_LEVEL } from '../app/_utils/log-level.util';

export const environment = {
    apiUrl: 'https://api.littlephil.org',
    authUrl: 'https://auth.littlephil.org',
    businessName: 'Little Phil',
    cookieDomain: '.littlephil.org',
    env: ENVIRONMENT.production,
    establishmentYear: 2018,
    googleMapsAPIKey: 'AIzaSyCsfYuykdQNYhI6qJIrSUWwH5Yje9wFtMM',
    googleTagManagerId: 'GTM-5SP3BWZ',
    intercomAppId: 's9uifzop',
    logLevel: LOG_LEVEL.info,
    shareLinkUrl: 'https://share.littlephil.org',
    production: true,
    recaptchaV3SiteKey: '6LfU4aoUAAAAAEEsc9CvO8Qh-Uwd5AAs4g6cnvSv',
    webBaseUrl: 'https://app.littlephil.org',
    widgetUrl: 'https://widgets.littlephil.org',
};
